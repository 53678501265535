import React from "react"

import type { LinkProps } from "./Link"
import { Link } from "./Link"

export type LinkListProps = {
  label?: string
  links: LinkProps[]
  sectionRef?: (node?: Element | null) => void
  className?: string
}

export const LinkList = ({ label, links, sectionRef, className }: LinkListProps) => (
  <div ref={sectionRef} className={className}>
    {!!label && <h3 className="link-list__title">{label}</h3>}
    <div className="link-list__list">
      {links.map((linkProps, key) => (
        <Link key={key} {...linkProps} />
      ))}
    </div>
  </div>
)
