import classNames from "classnames"
import React, { useState } from "react"

import { DateTime } from "@bounce/date"

import CalendarHeader from "./CalendarHeader"
import DayGrid from "./DayGrid"
import type { SelectedState } from "./types"
import WeekHeader from "./WeekHeader"

export type CalendarProps = {
  date?: DateTime
  minDate?: DateTime
  startDate?: DateTime
  onDateChange?: (date: DateTime) => void
  timezone?: string
  weekStartsOn?: 0 | 1
  isDateDisabled?: (date: DateTime) => boolean
  className?: string
}

export const Calendar = (props: CalendarProps) => {
  const timezone = props.timezone || DateTime.local().zoneName
  const {
    className,
    weekStartsOn = 0,
    onDateChange,
    isDateDisabled,
    minDate = DateTime.local().setZone(timezone),
    date = DateTime.local().setZone(timezone),
  } = props

  const [currentMonth, setCurrentMonth] = useState(date.startOf("month"))

  /**
   * Will get the selectedState of the component based on the date provided
   * @param d DateTime
   */
  const isDateSelected = (d: DateTime): SelectedState => {
    return d.hasSame(date, "day") ? "full" : "unselected"
  }

  const handleNextMonth = () => setCurrentMonth(s => s.plus({ months: 1 }))
  const handlePrevMonth = () => setCurrentMonth(s => s.minus({ months: 1 }))

  /**
   * Will call the right handler depending on whether
   * the type is "single" or "range" and the state of
   * "tempStartDate" and "tempEndDate"
   * @param d DateTime
   */
  const handleDateSelected = (d: DateTime) => {
    if (onDateChange) {
      onDateChange(d)
    }
  }

  return (
    <div className={classNames("calendar", className)}>
      <CalendarHeader date={currentMonth} onBackPress={handlePrevMonth} onNextPress={handleNextMonth} />
      <WeekHeader startOfWeek={weekStartsOn} className="mt-6" />
      <DayGrid
        className="mt-4"
        minDate={minDate}
        currentMonth={currentMonth}
        weekStartsOn={weekStartsOn}
        onDateSelected={handleDateSelected}
        isDisabled={isDateDisabled}
        isDaySelected={isDateSelected}
      />
    </div>
  )
}
