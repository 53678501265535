import classNames from "classnames"
import capitalize from "lodash/capitalize"
import * as React from "react"

import type { DateTime } from "@bounce/date"

import ArrowLeftIcon from "@bounce/assets/icons/ArrowLeft.svg"
import ArrowRightIcon from "@bounce/assets/icons/ArrowRight.svg"

type CalendarHeaderProps = {
  date: DateTime
  onBackPress: () => void
  onNextPress: () => void
  className?: string
}

const CalendarHeader = ({ date, onBackPress, onNextPress, className }: CalendarHeaderProps) => {
  const formattedDate = capitalize(date.toFormat("LLLL yyyy"))

  return (
    <div className={classNames("calendar__header", className)}>
      <button type="button" onClick={onBackPress} className="calendar__header-btn">
        <ArrowLeftIcon className="size-4 text-primary" />
      </button>
      <span className="calendar__header-label">{formattedDate}</span>
      <button type="button" onClick={onNextPress} className="calendar__header-btn">
        <ArrowRightIcon className="size-4 text-primary" />
      </button>
    </div>
  )
}

export default CalendarHeader
