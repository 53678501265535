import { useRouter } from "next/router"
import { useState } from "react"

import { Analytics } from "@bounce/analytics"
import type { DateTime } from "@bounce/date"
import type { GeocodingResult } from "@bounce/location"

import { InteractionEvents } from "../../../libs/analytics/events"
import { useUrl } from "@/libs/router"
import { trackSearchBar } from "@/utils/analytics"
import type { AppUrlOptions } from "@/utils/appURL"
import { DEFAULT_NUMBER_BAGS, goToApp, useAppURL } from "@/utils/appURL"

type Options = {
  ctaName: string
  initialQuery?: string
  couponCode?: string
}

export const useSearchBar = ({ initialQuery = "", couponCode, ctaName }: Options) => {
  const [query, setQuery] = useState(initialQuery)
  const { getUrl } = useUrl()
  const buildAppURL = useAppURL()
  const router = useRouter()

  const [numberOfBags, setNumberOfBags] = useState(DEFAULT_NUMBER_BAGS)
  const [checkInDate, setCheckInDate] = useState<DateTime | undefined>()

  const getSearchURL = (options?: AppUrlOptions) =>
    buildAppURL(getUrl({ path: "/s" }), {
      ...options,
      bags: numberOfBags,
      from: checkInDate,
      to: checkInDate?.plus({ hours: 3 }),
      coupon: couponCode,
    })

  const searchUrl = getSearchURL({ query })
  const searchNearMeUrl = getSearchURL()

  const onSearch = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.FormEvent) => {
    ev.preventDefault()
    trackSearchBar({
      event: InteractionEvents.LuggageStorageSearchBarSearchPressed,
      props: {
        pageName: router.route,
        pagePath: router.asPath,
        searchQuery: query,
        checkInDate: checkInDate?.toISODate(),
        numberOfBags,
        daysFromToday: Math.round(checkInDate?.diffNow("days").days ?? 0),
        cta: ctaName,
        changedLocation: query !== initialQuery,
      },
    })

    if (query) {
      location.assign(searchUrl)
      return
    }

    location.assign(searchNearMeUrl)
  }

  const onSelectRecommendation = (recommendation: GeocodingResult | undefined) => {
    if (!recommendation) return

    trackSearchBar({
      event: InteractionEvents.LuggageStorageSearchBarLocationSuggestionPressed,
      props: {
        pageName: router.route,
        pagePath: router.asPath,
        searchQuery: recommendation.query,
        searchQueryType: recommendation.scope,
        searchedCountry: recommendation.country,
        searchedCity: recommendation.city,
        searchedType: recommendation.scope,
        searchedSubtype: recommendation.type,
      },
    })

    goToApp(getSearchURL({ query: recommendation.query }))
  }

  const handleUpdateBags = (bags: number) => {
    Analytics.track(InteractionEvents.NumberOfBagsChanged)
    setNumberOfBags(bags)
  }

  const handleUpdateCheckInDate = (date: DateTime) => {
    Analytics.track(InteractionEvents.CheckInDateChanged)
    setCheckInDate(date)
  }

  return {
    handleUpdateCheckInDate,
    handleUpdateBags,
    onSelectRecommendation,
    onSearch,
    setQuery,
    searchUrl,
    numberOfBags,
    checkInDate,
  }
}
