import classNames from "classnames"

import type { DateTime } from "@bounce/date"
import { useTranslation } from "@bounce/i18n"
import { DateSelector } from "@bounce/web-components"

export type DatePickerProps = {
  handleUpdateCheckInDate: (date: DateTime) => void
  checkInDate: DateTime | undefined
  className?: string
}

export const DatePicker = ({ checkInDate, handleUpdateCheckInDate, className }: DatePickerProps) => {
  const { t } = useTranslation()

  return (
    <DateSelector
      strings={{
        today: t("cmp.searchBar.dateSelector.today", "Today"),
        submitButton: t("cmp.searchBar.dateSelector.submitButton", "Save"),
        closeButton: t("cmp.searchBar.dateSelector.closeButton", "Cancel"),
      }}
      value={checkInDate}
      onUpdate={handleUpdateCheckInDate}
      className={classNames("px-7 md:px-4", className)}
    />
  )
}
