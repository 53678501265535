import classNames from "classnames"
import React, { useMemo, useState } from "react"

import { DateTime } from "@bounce/date"

import { DatePicker } from "../DatePicker/DatePicker"

export type DateSelectorProps = {
  value: DateTime | undefined
  strings: {
    today: string
    submitButton: string
    closeButton: string
  }
  onUpdate: (value: DateTime) => void
  textClassName?: string
  className?: string
}

export const DateSelector = ({ value: _value, onUpdate, strings, className, textClassName }: DateSelectorProps) => {
  const [open, setOpen] = useState(false)
  const now = useMemo(() => DateTime.local(), [])
  const value = _value ?? now

  const formattedValue =
    value.toLocaleStringGlobal() === DateTime.local().toLocaleStringGlobal()
      ? strings.today
      : value
          .toLocaleStringGlobal({
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replace(/\//gi, "-")

  const closeDatePicker = () => setOpen(false)

  const updateValue = (newVal: DateTime) => {
    onUpdate(newVal)
  }

  const updateValueAndClose = (date: DateTime) => {
    updateValue(date)
    closeDatePicker()
  }

  return (
    <div className={classNames("date-selector", className)}>
      <button type="button" onClick={() => setOpen(!open)} data-chromatic="ignore" className="date-selector__btn">
        <span className={classNames("date-selector__label", textClassName)}>{formattedValue}</span>
      </button>
      {open && (
        <>
          <button type="button" className="date-selector__close" onClick={closeDatePicker} />
          <DatePicker
            className="date-selector__picker"
            onCancel={updateValueAndClose}
            initialStartDate={value}
            onChange={updateValue}
            onApply={closeDatePicker}
            strings={{
              submitButton: strings.submitButton,
              closeButton: strings.closeButton,
            }}
          />
        </>
      )}
    </div>
  )
}
