import React from "react"

import type { LinkProps as NextLinkProps } from "../../Navigation/Link/Link"
import { Link as NextLink } from "../../Navigation/Link/Link"

export type LinkProps = {
  name: string
} & NextLinkProps

export const Link = ({ name, ...props }: LinkProps) => (
  <NextLink className="link-list__link" {...props}>
    {name}
  </NextLink>
)
