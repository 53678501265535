import classNames from "classnames"
import * as React from "react"
import { useState } from "react"

import AddIcon from "@bounce/assets/icons/Add.svg"
import MinusIcon from "@bounce/assets/icons/Minus.svg"

export type NumberSelectorProps = {
  initialValue?: number
  min?: number
  max?: number
  onUpdate?: (value: number) => void
  className?: string
  minusAriaLabel: string
  plusAriaLabel: string
  strings?: {
    single: string
    plural: string
  }
  textClassName?: string
  iconClassName?: string
}

export const NumberSelector = ({
  initialValue = 2,
  min = 1,
  max,
  onUpdate,
  className,
  minusAriaLabel,
  plusAriaLabel,
  strings,
  textClassName,
  iconClassName,
}: NumberSelectorProps) => {
  const [value, setValue] = useState<number>(initialValue)

  const handleIncrement = () => {
    const newVal = value + 1
    if (max && newVal > max) return

    updateValue(newVal)
  }

  const handleDecrement = () => {
    const newVal = value - 1
    if (newVal < min) return

    updateValue(newVal)
  }

  const updateValue = (newVal: number) => {
    setValue(newVal)
    onUpdate?.(newVal)
  }

  const textPrefix = value > 1 ? strings?.plural : strings?.single
  const text = ` ${value} ${textPrefix || ""}`.trim()

  return (
    <div className={classNames("number-selector", className)}>
      <button type="button" className="number-selector__btn" aria-label={minusAriaLabel} onClick={handleDecrement}>
        <MinusIcon className={classNames("number-selector__icon", iconClassName)} />
      </button>
      <span className={classNames("number-selector__value", textClassName)}>{text}</span>
      <button type="button" className="number-selector__btn" aria-label={plusAriaLabel} onClick={handleIncrement}>
        <AddIcon className={classNames("number-selector__icon", iconClassName)} />
      </button>
    </div>
  )
}
