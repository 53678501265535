import fastdom from "fastdom"
import dynamic from "next/dynamic"
import React, { createContext, useContext, useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { useIsMobile } from "@bounce/web-components"

import type { StickyCtaProps } from "@/components/LuggageStorage/StickyCTA/StickyCTA"

type StickyCtaProviderProps = {
  children: React.ReactNode
  config: Pick<StickyCtaProps, "searchQuery" | "coupon" | "from" | "label" | "path">
}
type StickyCtaContextProps = {
  ref: (node?: Element | null) => void
  isVisible: boolean
}

const StickyCTAContext = createContext<StickyCtaContextProps>({
  ref: () => {},
  isVisible: false,
})

StickyCTAContext.displayName = "StickyCTAContext"

const StickyCTA = dynamic(() => import("@/components/LuggageStorage/StickyCTA/StickyCTA").then(mod => mod.StickyCTA), {
  ssr: false,
})

export const StickyCTAProvider = ({ children, config }: StickyCtaProviderProps) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
    initialInView: true,
  })
  const isMobile = useIsMobile()

  const isVisible = !inView && isMobile && window.scrollY > 200

  // Update Intercom launcher position when the CTA is visible
  useEffect(() => {
    const handleScroll = () => {
      const intercomLauncher = document.querySelector(".intercom-lightweight-app-launcher")
      fastdom.mutate(() => {
        intercomLauncher?.setAttribute("style", isVisible ? "bottom: 120px" : "")
      })
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isVisible])

  return (
    <StickyCTAContext.Provider
      value={{
        ref,
        isVisible,
      }}>
      {children}
      {isMobile && <StickyCTA isVisible={isVisible} {...config} />}
    </StickyCTAContext.Provider>
  )
}

export const useStickyCTA = () => useContext(StickyCTAContext)
