import classNames from "classnames"
import React, { useRef, useState } from "react"

import { DateTime, Settings, roundToNextHalf } from "@bounce/date"

import Separator from "../../Layout/Separator/Separator"
import { Calendar } from "../Calendar/Calendar"

export type DatePickerProps = {
  initialStartDate?: DateTime
  onCancel: (initialDate: DateTime) => void
  onApply: (startDate: DateTime) => void
  onChange?: (startDate: DateTime) => void
  isDateDisabled?: (date: DateTime) => boolean
  strings: {
    submitButton: string
    closeButton: string
  }
  className?: string
}

export const DatePicker = ({
  initialStartDate = DateTime.local(),
  isDateDisabled,
  onApply,
  onCancel,
  onChange,
  strings,
  className,
}: DatePickerProps) => {
  const initialDateRef = useRef(initialStartDate)
  const [startDate, setStartDate] = useState(initialDateRef.current)

  const handleDateChange = (startDate: DateTime) => {
    const now = roundToNextHalf(DateTime.local())
    const date = startDate.set({ minute: now.minute, hour: now.hour })

    onChange?.(date)
    setStartDate(date)
  }

  const handleApply = () => {
    onApply(startDate)
  }

  const handleClose = () => {
    onCancel(initialDateRef.current)
  }

  return (
    <div className={classNames("date-picker", className)}>
      <Calendar
        className="date-picker__calendar"
        date={startDate}
        onDateChange={handleDateChange}
        startDate={startDate}
        isDateDisabled={isDateDisabled}
        weekStartsOn={Settings.defaultWeekSettings.firstDay === 7 ? 0 : 1}
      />
      <Separator className="mt-4" />
      <div className="date-picker__footer">
        <button type="button" onClick={handleClose} className="date-picker__btn">
          <span className="text-neutral-800">{strings.closeButton}</span>
        </button>
        <button type="button" onClick={handleApply} className="date-picker__btn">
          <span className="text-primary">{strings.submitButton}</span>
        </button>
      </div>
    </div>
  )
}
