import classNames from "classnames"
import * as React from "react"

import type { DateTime } from "@bounce/date"

import type { SelectedState } from "./types"

type DayProps = {
  selected?: SelectedState
  // Whether the date is disabled for the specified date
  disabled?: boolean
  // Whether the date falls within the min and max date
  unavailable?: boolean
  thisMonth?: boolean
  date: DateTime
  onDaySelected?: (date: DateTime) => void
}

const getTextStyle = ({ selected, unavailable }: Pick<DayProps, "selected" | "unavailable">) => {
  if (selected === "full") {
    return "calendar__day--selected"
  }

  if (unavailable) {
    return "calendar__day--unavailable"
  }

  return "calendar__day--unselected"
}

const Day = ({ date, onDaySelected, disabled, unavailable, selected }: DayProps) => {
  const handlePress = () => {
    if (disabled || unavailable) return

    onDaySelected?.(date)
  }

  return (
    <button
      type="button"
      className={classNames(`calendar__day`, getTextStyle({ selected, unavailable }))}
      onClick={handlePress}>
      <span>{date.toFormat("d")}</span>
    </button>
  )
}

export default Day
