import classNames from "classnames"

import { useTranslation } from "@bounce/i18n"
import { NumberSelector } from "@bounce/web-components"

export type BagPickerProps = {
  handleUpdateBags: (bags: number) => void
  numberOfBags: number
  className?: string
}

export const BagPicker = ({ numberOfBags, handleUpdateBags, className }: BagPickerProps) => {
  const { t } = useTranslation()

  return (
    <NumberSelector
      onUpdate={handleUpdateBags}
      initialValue={numberOfBags}
      minusAriaLabel={t("cmp.searchBar.minusAriaLabel", "Remove a bag")}
      plusAriaLabel={t("cmp.searchBar.plusAriaLabel", "Add another bag")}
      strings={{
        single: t("cmp.searchBar.singleBagString", "Bag"),
        plural: t("cmp.searchBar.multipleBagsString", "Bags"),
      }}
      className={classNames("px-4", className)}
    />
  )
}
