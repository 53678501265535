import classNames from "classnames"
import * as React from "react"

import { DateTime, parseToStartSunday } from "@bounce/date"

type WeekHeaderProps = {
  startOfWeek?: 0 | 1
  className?: string
}

const weekDayNumbers = [1, 2, 3, 4, 5, 6, 7]

const getDayText = (dayOfWeek: number, startOfWeek = 0) => {
  const day = startOfWeek === 1 ? dayOfWeek : parseToStartSunday(dayOfWeek)
  const date = DateTime.local().set({ weekday: day })

  return date.toFormat("ccc")
}

const WeekHeader = ({ startOfWeek, className }: WeekHeaderProps) => (
  <div className={classNames("calendar__week-header", className)}>
    {weekDayNumbers.map(d => (
      <div className="calendar__week-header-day" key={d}>
        <span>{getDayText(d, startOfWeek)}</span>
      </div>
    ))}
  </div>
)

export default WeekHeader
